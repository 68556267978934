import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NbDateService } from '@nebular/theme';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';
import { ToastrMessages } from '../../../helpers/toaster.service';
import { LocalDataSource } from 'ng2-smart-table';
@Component({
  selector: 'ngx-adsAdd',
  styleUrls: ['./view.component.scss'],
  templateUrl: './view.component.html',
})
export class ViewComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private dateService: NbDateService<Date>,
    private http: HttpClient,
    private toast: ToastrMessages,
    
  ) {
  }

  settings = {
    mode: 'external',
    actions: {
      columnTitle:"Actions",
      position: 'right', // left|right
      add: false,
      edit:false,
    },

    // add: {
    //   addButtonContent: '<i class="nb-plus"></i>',
    //   createButtonContent: '<i class="nb-checkmark"></i>',
    //   cancelButtonContent: '<i class="nb-close"></i>',
    // },

    // edit: {
    //   editButtonContent: '<i class="ion-checkmark"></i>',
    //   saveButtonContent: '<i class="nb-checkmark"></i>',
    //   cancelButtonContent: '<i class="nb-close"></i>',
    // },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },

   
    columns: {
      userName:{
        title: 'Name',
        type: 'string'
      },
      message: {
        title: 'message',
        type: 'string',
      },
    },
  };

  baseUrl = environment.baseUrl;
  spinner = false;
  imagePath;
  fileData: File = null;
  eventForm: FormGroup;
  submitted = false;
  imgURL: any;
  message: string;
  source: LocalDataSource = new LocalDataSource();

  ngOnInit() {
     this.getReqeustList();
  }

  getReqeustList(){
     this.http.get(this.baseUrl + 'getContactUs').subscribe(
      (response: any) => {
        console.log(response);
        //this.source.load(response.body);

      response.body.map(item=>{
          item['userName'] = item['appUser.name'];
          delete item['appUser.name'];
          return item;

       })
       this.source.load(response.body);
      },
      (error) => {
    });     
  }



  onDelete(item){
  //console.log(item.data.id);
    this.http.delete(this.baseUrl + 'deleteContactUsMsg/'+item.data.id).subscribe(
        (response: any) => {
          this.toast.showToast(NbToastStatus.SUCCESS, 'User Message', response.message);
          this.getReqeustList();
        },
        (error) => {
          this.toast.showToast(NbToastStatus.DANGER, 'User Message',error);
     });   
  }

  onEdit(item){
   // console.log(item);

  }

}
