import { NgModule } from '@angular/core';
//import { CKEditorModule } from 'ng2-ckeditor';

import { ThemeModule } from '../../@theme/theme.module';
import { CmsRoutingModule } from './cms-routing.module';
import { CmsComponent } from './cms.component';
import { ViewComponent } from './view/view.component';
import { EditComponent } from './edit/edit.component';
import { CKEditorComponent } from './ckeditor/ckeditor.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ToastrMessages } from '../../helpers/toaster.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../../interceptor/auth.interceptor';


const components = [
  CmsComponent,
  ViewComponent,
  EditComponent,
  CKEditorComponent,
];

@NgModule({
  imports: [
    ThemeModule,
    CmsRoutingModule,
    Ng2SmartTableModule,
    //CKEditorModule,
  ],
  declarations: [
    ...components,
  ],
  providers: [
    ToastrMessages,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ,
   ],
})
export class CmsModule { }
