// import { Component } from '@angular/core';

// import './ckeditor.loader';
// import 'ckeditor';

// @Component({
//   selector: 'ngx-ckeditor',
//   templateUrl: './ckeditor.component.html',
// })
// export class CKEditorComponent {
// }

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ToastrMessages } from '../../../helpers/toaster.service';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';

import './ckeditor.loader';
import 'ckeditor';

@Component({
  selector: 'ngx-ckeditor',
  templateUrl: './ckeditor.component.html',
})
export class CKEditorComponent implements OnInit {
  baseUrl = environment.baseUrl;
  brandStatus: string;
  spinner = false;
  dspinner: boolean = false;
  cros: boolean;
  pageData: any = {};

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastrMessages,

  ) {
    // =================================Get & set the university detail=============================
    this.http.get(this.baseUrl + 'page/' + this.route.snapshot.paramMap.get('id')).subscribe(
      (response: any) => {
        console.log(this.route.snapshot.paramMap.get('id'));
        console.log(response.body);
        this.pageData = response.body;
        this.f['description'].setValue(response.body.description);
      }, (error) => {
        this.toast.showToast(NbToastStatus.DANGER, 'Invalid Page!', 'Please Check Page Id');
      },
    );
  }
  pageForm: FormGroup;
  submitted = false;
  message: string;
  @ViewChild('form') form;
  ngOnInit() {
    // initilising form 
    this.pageForm = this.formBuilder.group({
      description: ['', [Validators.required, Validators.minLength(5)]],
    });
  }
  
  // convenience getter for easy access to form fields
  get f() { return this.pageForm.controls; }
  ////// ========================== updating university  function =========================== //////
  updatePage() {
    
    this.submitted = true;
    if (this.pageForm.invalid) {
      return;
    }
    // console.log(this.pageData);
    const formData = new FormData();
    formData.append('id', this.route.snapshot.paramMap.get('id'));
    formData.append('description', this.f.description.value);
    let data  = {"id":this.route.snapshot.paramMap.get('id'),"description":this.f.description.value}
    console.log(this.route.snapshot.paramMap.get('id'));
    console.log(this.f.description.value);
    // console.log(formData);
    this.http.put(this.baseUrl + 'updatePage', data).subscribe(
      (response: any) => {
        console.log('cms');
        console.log(response.body.description);
        if (response.message == 'Error') {
          this.toast.showToast(NbToastStatus.DANGER, 'Error', response.body);
        } else if (response.message === 'Not Modified') {
          this.toast.showToast(NbToastStatus.WARNING, 'Page', this.pageData.title + " "+ response.body);
        } else if (response.message == 'Updated') {
          this.toast.showToast(NbToastStatus.SUCCESS, 'Page', this.pageData.title +" "+ response.body);
        }
      },
      (error) => {
        this.spinner = false;
        this.toast.showToast(NbToastStatus.DANGER, 'Error', error.error.message);
      });
  }

}

