import { Component, OnDestroy } from '@angular/core';
import { NbMediaBreakpoint, NbMediaBreakpointsService, NbThemeService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators';
import { CountryOrderData } from '../../../@core/data/country-order';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-country-orders',
  styleUrls: ['./country-orders.component.scss'],
  template: `
    <nb-card [size]="breakpoint.width >= breakpoints.md ? 'medium' : 'xxlarge'">
      <nb-card-header>More Statistics</nb-card-header>
      <nb-card-body>
        <ngx-country-orders-map (select)="selectCountryById($event)"
                                countryId="ESP">
        </ngx-country-orders-map>
        <ngx-country-orders-chart [countryName]="countryName"
                                  [data]="countryData"
                                  [labels]="countriesCategories"
                                  maxValue="20">
        </ngx-country-orders-chart>
      </nb-card-body>
    </nb-card>
  `,
})
export class CountryOrdersComponent implements OnDestroy {

  private alive = true;
  baseUrl = environment.baseUrl;
  countryName = '';
  events: number;
  festivities: number;
  services: number;
  parties: number;
  countryData = [1, 2, 3, 4, 5];
  countriesCategories: string[];
  breakpoint: NbMediaBreakpoint = { name: '', width: 0 };
  breakpoints: any;

  constructor(private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
    private countryOrderService: CountryOrderData,
    private http: HttpClient,
  ) {
    this.breakpoints = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(takeWhile(() => this.alive))
      .subscribe(([oldValue, newValue]) => {
        this.breakpoint = newValue;
      });
    this.countryOrderService.getCountriesCategories()
      .pipe(takeWhile(() => this.alive))
      .subscribe((countriesCategories) => {
        this.countriesCategories = countriesCategories;
      });
  }

  selectCountryById(countryName: string) {
    this.countryName = 'Spain';
    this.countryData = [];
    //this.countryData = [];

    for (let i = 0; i < 5; i++) {
      this.countryData.push(i + 7);
    }

    // this.countryOrderService.getCountriesCategoriesData(countryName)
    //   .pipe(takeWhile(() => this.alive))
    //   .subscribe((countryData) => {
    //     console.log(countryData)
    //     this.countryData = countryData;
    //     console.log(this.countryData);
    //   });
    this.http.get(this.baseUrl + 'totalEvents')
      .subscribe(
        (response: any) => {
          this.events = response.body.count;
        });
    this.http.get(this.baseUrl + 'totalFestivities').subscribe(
      (response: any) => {
        this.festivities = response.body.count;
      });
    this.http.get(this.baseUrl + 'totalServices')
      .subscribe(
        (response: any) => {
          this.services = response.body.count;
        });
    this.http.get(this.baseUrl + 'totalParties')
      .subscribe(
        (response: any) => {
          this.parties = response.body.count;
        });
    setTimeout(() => {
      this.countryData = [];
      console.log(this.events);
      this.countryData.push(this.events);
      this.countryData.push(this.festivities);
      this.countryData.push(this.parties);
      this.countryData.push(this.services);
    }, 1000);



    // this.http.get(this.baseUrl + 'totalSponsers').subscribe(
    //   (response: any) => {
    //     this.countryData.push(response.body.count);

    //   })
    // this.http.get(this.baseUrl + 'totalDeaths').subscribe(
    //   (response: any) => {
    //     this.countryData.push(response.body.count);
    //     this.countryData.push(response.body.count);

    //   })
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
