import {RequestComponent} from './requests.component'
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewComponent } from './view/view.component';

const routes: Routes = [{
  path: '',
  component: RequestComponent,
  children: [
    {
      path: 'add',
      component: ViewComponent,
    },{
    path: 'view',
    component: ViewComponent,
  }],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RequestRoutingModule { }
