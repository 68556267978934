import { NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { ThemeModule } from '../../@theme/theme.module';
import { AnnouncementComponent } from './announcement.component';
import { AnnouncementRoutingModule } from './announcement-routing.module';
import { ViewComponent } from './view/view.component';
import { AddComponent } from './add/add.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ToastrMessages } from '../../helpers/toaster.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../../interceptor/auth.interceptor';

@NgModule({
  imports: [
    ThemeModule,
    NgxEchartsModule,
    AnnouncementRoutingModule,
    Ng2SmartTableModule
  ],
  declarations: [
    AnnouncementComponent,
    ViewComponent,
    AddComponent
  ],
  providers: [
    ToastrMessages,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
})
export class AnnouncementModule { }
