import { Component, OnInit, ViewChild, NgZone, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ToastrMessages } from '../../../helpers/toaster.service';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';

import {} from 'googlemaps';
import { MapsAPILoader } from '@agm/core';
@Component({
  selector: 'ngx-edit',
  styleUrls: ['./edit.component.scss'],
  templateUrl: './edit.component.html',
})
export class EditComponent implements OnInit {
  baseUrl = environment.baseUrl;
  imagesUrl = environment.imagesUrl;
  categoryStatus: string;
  spinner = false;
  numbers:any
  latitude: any;
  language: any;
  longitude: any;
  address: any;
  @ViewChild('search', null)
  public searchElementRef: ElementRef;
  // categoryName;
  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastrMessages,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
  ) {
    this.http.get(this.baseUrl + 'category/' + this.route.snapshot.paramMap.get('id')).subscribe(
      (response: any) => {

        this.f['name'].setValue(response.body.group_name);
        this.f['language'].setValue(response.body.language);
        //  this.categoryName = response.body.name;
        this.f['address'].setValue(response.body.address);
        this.f['image'].setValue(response.body.group_icon);
        this.f['addedBy'].setValue(response.body.email);
        this.address = response.body.address;
        this.latitude = response.body.latitude;
        this.longitude = response.body.longitude;
        this.categoryStatus = response.body.status;
        this.imgURL = this.imagesUrl + response.body.group_icon;
      }, (error) => {
        this.toast.showToast(NbToastStatus.DANGER, 'Invalid Category!', 'Please Check Category Id');
      },
    );
  }
  imagePath;
  fileData: File = null;
  categoryForm: FormGroup;
  submitted = false;
  imgURL: any;
  message: string;
  @ViewChild('form') form;
  ngOnInit() {
    this.initAutocomplete();
    this.numbers = Array.from(Array(101).keys())
    this.numbers.splice(0,1);
    this.categoryForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4)]],
      address: ['', [Validators.required ]],
      language: ['', [Validators.required ]],
      image: [this.fileData, Validators.required],
      addedBy: [],
    });
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      this.toast.showToast(NbToastStatus.DANGER, 'Error', this.message);
      return;
    }
    this.f['image'].setValue(files[0].name ? files[0].name : '');
    const reader = new FileReader();
    this.imagePath = files;
    this.fileData = <File>files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }

  // convenience getter for easy access to form fields
  get f() { return this.categoryForm.controls; }

  initAutocomplete() {
    console.log('initAutocomplete enters...');
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.address = place.formatted_address
          console.log(this.latitude, this.longitude)
          console.log("place...", place)

        });
      });
    });

  }
  ////// ========================== super admin login function =========================== //////


  updateCategory() {
    this.submitted = true;
    if (this.categoryForm.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('id', this.route.snapshot.paramMap.get('id'));
    formData.append('name', this.f.name.value);
    formData.append('language', this.f.language.value);
    formData.append('address', this.address);
    formData.append('latitude', this.latitude);
    formData.append('longitude', this.longitude);
    if (this.fileData) {
      formData.append('image', this.fileData);
      formData.append("imageChanged", "yes")

    }
    else {
      formData.append("imageChanged", "no")

    }
    this.http.put(this.baseUrl + 'category', formData).subscribe(
      (response: any) => {
        if (response.message == 'Exists' || response.message == 'Error') {
          this.toast.showToast(NbToastStatus.DANGER, 'Error', response.body);
        } else if (response.message === 'Not Modified') {
          this.toast.showToast(NbToastStatus.WARNING, 'Category', response.body);
        } else if (response.message == 'Updated') {
          this.router.navigate(['pages/categories/view']);
          this.toast.showToast(NbToastStatus.SUCCESS, 'Category', response.body);
        }
      },
      (error) => {
        this.spinner = false;
        this.toast.showToast(NbToastStatus.DANGER, 'Error', error.error.message);
      });
  }

  ////// ========================== update cateory status ================================= //////

  updateStatus(status: string) {
    this.spinner = true;
    this.http.put(this.baseUrl + 'categoryStatus', { id: this.route.snapshot.paramMap.get('id'), status: status })
      .subscribe(
        (response: any) => {
          this.spinner = false;
          this.toast.showToast(NbToastStatus.SUCCESS, response.message, response.body);
          if (status === 'Active') {
            this.categoryStatus = 'Active';
          } else {
            this.categoryStatus = 'Inactive';
          }
        }, (error) => {
          this.spinner = false;
          this.toast.showToast(NbToastStatus.DANGER, 'Error', 'Something Went Wrong.Please Try Again!');
        });
  }

  ////// ========================== update cateory status ================================= //////

  deleteCategory() {
    if (confirm('Are you sure to delete this category?')) {
      this.spinner = true;
      this.http.delete(this.baseUrl + 'category/' + this.route.snapshot.paramMap.get('id'))
        .subscribe(
          (response: any) => {
            this.router.navigate(['/pages/categories/view']);
          }, (error) => {
            this.spinner = false;
            this.toast.showToast(NbToastStatus.DANGER, 'Error', 'Something Went Wrong.Please Try Again!');
          });
    }
  }
}
