import { Component, OnInit, Input, Output, ElementRef, ViewChild, EventEmitter, TemplateRef, AfterViewInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { ToastrMessages } from '../../../helpers/toaster.service';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';
import { ViewCell } from 'ng2-smart-table'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-view',
  templateUrl: './view.component.html',
  styles: [`
    nb-card {
      transform: translate3d(0, 0, 0);
    }
    ::ng-deep{
      .nb-theme-default ng2-smart-table .ng2-smart-actions {
        height:auto !important;
      }
      .ng2-smart-action-custom-custom{
        display: inline !important;
      }
    }
    .capsules {
        padding: 6px;
        border-radius: 20px;
        outline: none;
        position:relative;
        min-width: 110px;
        text-align:left;
    }
    .capsules img{
      border-radius:15px;
    }

    .cross-icon {
      position: absolute;
      top: -12px;
      right: -13px;
      font-size: 25px;
      color: #fff !important;
      font-weight: bold;
      background: red;
      border-radius: 15px;
      cursor: pointer;
    }
  `],
})
export class ViewComponent implements AfterViewInit {
  baseUrl = environment.baseUrl;
  imagesUrl = environment.imagesUrl;
  dataLoaded: boolean = false
  @ViewChild("groupsModel") elementView: ElementRef;

  settings = {
    mode: 'external',
    actions: {
      position: 'right', // left|right
      add: false,
      edit: false,
      delete: false,
      custom: [
        { name: 'users', title: '<i class="nb-person"></i>', class: 'd-inline' },
        { name: 'edit', title: '<i class="nb-edit"></i>', class: 'd-inline' },
        { name: 'delete', title: '<i class="nb-trash"></i>', class: 'd-inline' },
      ]
    },
    columns: {
      // id: {
      //   title: 'ID',
      //   type: 'number',
      // },
      group_icon: {
        title: 'Image',
        type: 'html',
        filter: false,
        valuePrepareFunction: (image: string) => `<img width="30px" src="${this.imagesUrl}${image}" />`,
      },
      group_name: {
        title: 'Name',
        type: 'string',
      },
      language: {
        title: 'Language',
        type: 'string',
      },
      latitude: {
        title: 'Latitude',
        type: 'string',
        filter: false,
      },
      longitude: {
        title: 'Longitude',
        type: 'string',
        filter: false,
      },

      address: {
        title: 'Address',
        type: 'string',
      },
      // check: {
      //   title: 'add Admin',
      //   type: 'custom',
      //   renderComponent: ButtonViewComponent,
      //   onComponentInitFunction(instance) {
      //     instance.save.subscribe(row => {
      //       //alert(`${row} saved!`)
      //      // this.clicked();

      //     });
      //   }
      // },

      //  status: {
      //     title: 'Status',
      //     type: 'custom',
      //     renderComponent: ButtonViewComponent,
      //     onComponentInitFunction(instance) {
      //       instance.save.subscribe(row => {
      //       });
      //     }

      //   },


    },
  };

  source: LocalDataSource = new LocalDataSource();
  groupUsers: any = [];
  selectedGroup: number;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toast: ToastrMessages,
    private _NgbModal: NgbModal

  ) {
    ////// ==================== get list of all categories ==================== //////

    this.getAllCategory();

  }

  ngAfterViewInit() {
    this.updateTablecss(2000);
  }

  updateTablecss(timer: number) {
    setTimeout(() => {
      Array.from(document.getElementsByClassName('ng2-smart-action-custom-custom')).forEach(elem => {
        elem.classList.add('d-inline');
        elem.parentElement.parentElement.style.height = 'auto';
      });
    }, timer);

  }

  removeUser(userId: number, index: number) {
    console.log(userId);
    if (confirm('Are you sure to remove this user from group?')) {
      this.http.post(this.baseUrl + 'categories/remove-member/', { "groupId": this.selectedGroup, "userId": userId }).subscribe(
        (newresponse: any) => {
          console.log(newresponse);
          //if(newresponse.body.status){
          this.groupUsers.splice(index, 1)
          //}
        },
        (error) => {
          this.dataLoaded = true;
        });
    }
  }



  getAllCategory() {
    this.http.get(this.baseUrl + 'categories').subscribe(
      (response: any) => {

        response.body.map(item => {
          item['check'] = "0";
        })

        this.source.load(response.body);
      },
      (error) => {
      });

  }

  ////// ====================== edit category ================================= //////
  onCustom(event): void {
    switch (event.action) {
      case 'edit':
        this.onEdit(event);
        break;
      case 'delete':
        this.onDelete(event);
        break;
      case 'users':
        this.groupMemberList(event.data, null);
        break;
    }
    this.updateTablecss(0);
  }

  ////// ====================== edit category ================================= //////
  onEdit(event): void {
    this.router.navigate(['pages/categories/edit/' + event.data.id]);
  }

  ////// ====================== delete category ================================= //////
  onDelete(event): void {
    if (confirm('Are you sure to delete this category?')) {
      this.http.get(this.baseUrl + 'categorydelete/' + event.data.id)
        .subscribe(
          (response: any) => {
            this.http.get(this.baseUrl + 'categories').subscribe(
              (newresponse: any) => {
                if (response.message == 'Deleted') {
                  this.toast.showToast(NbToastStatus.SUCCESS, 'Category', response.body);
                }
                this.getAllCategory();
              },
              (error) => {
              });
          });
    }
  }

  groupMemberList(item: any, model) {

    this.dataLoaded = false;
    this.groupUsers = [];

    let groupAdmin = [];

    this._NgbModal.open(this.elementView, {
      windowClass: 'modal-job-scrollable',
      size: 'lg'
    });
    // this.http.get(this.baseUrl + 'allGroupUsers/'+item.id).subscribe(
    this.http.post(this.baseUrl + 'categories/member/', { "groupId": item.id }).subscribe(
      (newresponse: any) => {
        this.dataLoaded = true;
        this.groupUsers = [];
        if (newresponse.body.length > 0) {
          this.selectedGroup = item.id;

          this.groupUsers = newresponse.body;

          this.groupUsers.map(element => {
            element['status'] = false;
          });

          this.groupUsers.sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          });

        }


      },
      (error) => {
        this.dataLoaded = true;
      });
  }

  saveAdminList(item, $event: boolean) {
    let data = {
      userId: item.userId,
      groupId: item.groupId,
      status: $event
    }
    this.http.post(this.baseUrl + 'updateGroupAdmin/', data).subscribe(
      (response: any) => {
        console.log(response);

      });
  }
}
