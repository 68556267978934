import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NbDateService } from '@nebular/theme';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';
import { ToastrMessages } from '../../../helpers/toaster.service';
import { MENU_ITEMS } from '../../pages-menu';
@Component({
  selector: 'ngx-adsAdd',
  styleUrls: ['./add.component.scss'],
  templateUrl: './add.component.html',
})
export class AddComponent implements OnInit {


  @Output() positionChanged = new EventEmitter<Location>();
  @ViewChild('search')
  public search: ElementRef;
  time: any;
  tcode: string;
  menu: any;
  selected: any;
  validEmail: boolean;
  emailError:any;
  UNameError="";
  formError: string;
  result: string;


  constructor(
    private formBuilder: FormBuilder,
    private dateService: NbDateService<Date>,
    private http: HttpClient,
    private toast: ToastrMessages,
    
  ) {
  }
  baseUrl = environment.baseUrl;

  spinner = false;
  imagePath;
  fileData: File = null;
  eventForm: FormGroup;
  submitted = false;
  imgURL: any;
  message: string;

  @ViewChild('form') form;
  ngOnInit() {
    this.emailError='';
  var data=[];
  for(let i=0;i < MENU_ITEMS.length; i++){
    if(i==0){

    }
    else if(i==1){

    }
    else if(i==2){

    }
    else{
        data.push(MENU_ITEMS[i])
    }
  }
    
    this.menu=data;
    this.eventForm = this.formBuilder.group({
      uName: ['', [Validators.required, Validators.minLength(4)]],
      image: [this.fileData],
      pwd: ['', Validators.required],
      roles: ['1',Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
    this.imgURL = 'https://dummyimage.com/400x170/E9EFF4/000000&text=Main+Photo';
    this.f['image'].setValue('https://dummyimage.com/400x170/E9EFF4/000000&text=Main+Photo');
  }
  roles(){
// console.log (this.f.roles.value)
this.selected=this.f.roles.value;
  }
  email(){
    console.log("called")
    if (this.f.email.invalid) {
    }
    else{
this.http.get(this.baseUrl + 'chaeckMail/'+ this.f.email.value).subscribe(
  (response: any) => {
    if(response.body=="Valid email"){
      // this.validEmail=true;
      this.emailError='';
    }
    else{
      // this.validEmail=false;
      this.emailError="err"
    }
  })
}
  }
  uName(){
    if (this.f.uName.invalid) {
     }
     else{
 this.http.get(this.baseUrl + 'chaeckUName/'+ this.f.uName.value).subscribe(
   (response: any) => {
    //  console.log(response)
     
     if(response.body=="Valid userName"){
       this.UNameError='';
     }
     else{
       this.UNameError="err"
     }
   })
 }
  }
  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    this.f['image'].setValue(files[0].name ? files[0].name : '');
    const reader = new FileReader();
    this.imagePath = files;
    this.fileData = <File>files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }
  // convenience getter for easy access to form fields
  get f() { return this.eventForm.controls; }
  ////// ========================== super admin login function =========================== //////

  addEvent() {

    this.submitted = true;
    if(this.UNameError && this.emailError!=''){
      this.formError="Please input Valid data"
      return;
    }
    if (this.eventForm.invalid) {
      this.formError="Please input Valid data"
      // console.log("invalid")
      return;
    }
    // --------------------------------------------------------------          
    this.spinner = true;
    this.formError=""
    const formData = new FormData();
    formData.append('userName', this.f.uName.value);
    formData.append('password', this.f.pwd.value);
    // console.log(this.f.roles.value.length)
    for(let i=0;i<this.f.roles.value.length;i++){
      // console.log(this.f.roles.value[i],this.f.roles.value[i].index ,i)
      if(i>0){
        this.result += ','; 
        this.result += this.f.roles.value[i].index;
     }
     else if(i==0){
      this.result = this.f.roles.value[i].index;
     }
    }
    // return
    formData.append('roleId', this.result);
    formData.append('email', this.f.email.value);
    formData.append('image', this.fileData);
    this.http.post(this.baseUrl + 'adduser', formData).subscribe(
      (response: any) => {
        this.spinner = false;
        if (response.message === 'Exists' || response.message === 'Error') {
          this.toast.showToast(NbToastStatus.DANGER, 'Error', response.body);
        } else if (response.message === 'Added') {
          this.toast.showToast(NbToastStatus.SUCCESS, 'User', response.body);
          this.form.nativeElement.reset();
          this.imgURL = 'https://dummyimage.com/400x170/E9EFF4/000000&text=Main+Photo';
    this.f['image'].setValue('https://dummyimage.com/400x170/E9EFF4/000000&text=Main+Photo');
          // this.imgURL = '';
          // tslint:disable-next-line: forin
          for (const i in this.eventForm.controls) {
            this.eventForm.controls[i].setErrors(null);
          }
        }
      },
      (error) => {
        this.spinner = false;
        this.toast.showToast(NbToastStatus.DANGER, 'Error', 'Something Went Wrong.Please Try Again!');
      });
  }
}
