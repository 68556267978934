import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [

  {
    title: 'Dashboard',
    icon: 'nb-home',
    link: '/pages/dashboard',

  },
  {
    title: 'FEATURES',
    group: true,

  },
  // {
  //   title: 'Admin Users',
  //   icon: 'nb-person',
  //   link: '/pages/users',

  //   children: [
  //     {
  //       title: 'Add',
  //       link: '/pages/users/add',
  //     },
  //     {
  //       title: 'View',
  //       link: '/pages/users/view',
  //     },
  //   ],
  // },


  {
    title: 'App Users',
    icon: 'nb-person',
    link: '/pages/appusers',

    children: [
      {
        title: 'Add',
        link: '/pages/appusers/add',
      },
      {
        title: 'View',
        link: '/pages/appusers/view',
      },
    ],
  },
  {
    title: 'Groups',
    icon: 'nb-keypad',
    link: '/pages/categories',

    children: [
      {
        title: 'Add',
        link: '/pages/categories/add',
      },
      {
        title: 'View',
        link: '/pages/categories/view',
      },
    ],
  },
  {
    title: 'CMS Pages',
    icon: 'nb-gear',
    link: '/pages/cms',
    children: [
      {
        title: 'View',
        link: '/pages/cms/view',
      },
    ],
  },
  {
    title: 'Announcement',
    icon: 'nb-person',
    link: '/pages/announcement',
    children: [
      {
        title: 'View',
        link: '/pages/announcement/view',
      },
      {
        title: 'Add',
        link: '/pages/announcement/add',
      },
    ],
  },
  // {
  //   title: 'Stiker',
  //   icon: '	nb-lightbulb',
  //   link: '/pages/stiker',
  //   children: [
  //     {
  //       title: 'View',
  //       link: '/pages/stiker/view',
  //     },
  //     {
  //       title: 'Add',
  //       link: '/pages/stiker/add',
  //     },
  //   ],
  // },
  // {
  //   title: 'Request',
  //   icon: 'nb-compose',
  //   link: '/pages/requests',

  //   children: [
  //       {
  //       title: 'View',
  //       link: '/pages/requests/view',
  //     },
  //   ],
  // },

  // {
  //   title: 'Client List',
  //   icon: 'nb-keypad',
  //   link: '/pages/clients',

  //   children: [
  //     {
  //       title: 'View',
  //       link: '/pages/clients/view',
  //     },

  //   ],
  // },
  //   {
  //   title: 'Contact Us Messages',
  //   icon: 'nb-email',
  //   link: '/pages/usermsg',

  //   children: [
  //     {
  //       title: 'View',
  //       link: '/pages/usermsg/view',
  //     },
  //   ],
  // }


];
