
import { NgModule } from '@angular/core';
import { ThemeModule } from '../../@theme/theme.module';
import { UserMsgRoutingModule } from './usermsg-routing.module';
import { ViewComponent } from './view/view.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ToastrMessages } from '../../helpers/toaster.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../../interceptor/auth.interceptor';
import { UserMessagesComponent} from './usermsg.component'
 
const components = [
  UserMessagesComponent,
  ViewComponent  
 ];

@NgModule({
  imports: [
    ThemeModule,
    UserMsgRoutingModule,
    Ng2SmartTableModule,
    ],
  declarations: [
    ...components,
  ],
  providers: [
    ToastrMessages,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
   ],
  
})
export class UserMsgModule { }
