import { AddComponent } from './add/add.component';
import { NgModule } from '@angular/core';
import { ThemeModule } from '../../@theme/theme.module';
import { AppUsersRoutingModule } from './root-routing.module';
import { ViewComponent } from './view/view.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ToastrMessages } from '../../helpers/toaster.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../../interceptor/auth.interceptor';
import { AppUsersComponents } from './root.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { UiSwitchModule } from 'ngx-ui-switch';
import { GroupsComponent } from './groups/groups.component';
import { GroupusersComponent } from './groupusers/groupusers.component';

 
const components = [
  AppUsersComponents,
  ViewComponent,
  AddComponent,
  GroupsComponent,
  GroupusersComponent
 ];

@NgModule({
  imports: [
    ThemeModule,
    AppUsersRoutingModule,
    Ng2SmartTableModule,
    NgMultiSelectDropDownModule,
    UiSwitchModule.forRoot({   
    })
  ],
  declarations: [
    ...components,
    GroupsComponent,
    GroupusersComponent,
  ],
  providers: [
    ToastrMessages,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
   ]
  
})
export class RootModule { }
