import { Component } from '@angular/core';

@Component({
  selector: 'ngx-cms',
  template: `
    <router-outlet></router-outlet>
  `,
})
export class CmsComponent {
}
