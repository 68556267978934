import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'ngx-chart-panel-summary',
  styleUrls: ['./chart-panel-summary.component.scss'],
  template: `
    <div class="summary-container">
      <div class="summory" *ngFor="let item of summaryy">
        <div class="title">{{ item.title }}</div>
        <div class="value">{{ item.value }}</div>
      </div>
    </div>
  `,
})
export class ChartPanelSummaryComponent {
  @Input() summary: {title: string; value: number}[];
  baseUrl=environment.baseUrl;
  summaryy:any = [
      {
        title: 'Events',
        value: 0,
      },
      {
        title: 'Festivities',
        value: 0,
      },
      {
        title: 'Sponsers',
        value: 0,
      },
      {
        title: 'Deaths',
        value: 0,
      },
    ];
  events: any;
  
  constructor(private http: HttpClient) {
 this.http.get(this.baseUrl + 'totalEvents').subscribe(
    (response: any) => {
      this.events=response.body.count;
      this.summaryy[0].value= this.events
    })
    this.http.get(this.baseUrl + 'totalFestivities').subscribe(
      (response: any) => {
        this.events=response.body.count;
        this.summaryy[1].value= this.events
      })
      this.http.get(this.baseUrl + 'totalSponsers').subscribe(
        (response: any) => {
          this.events=response.body.count;
          this.summaryy[2].value= this.events
        })
        this.http.get(this.baseUrl + 'totalDeaths').subscribe(
          (response: any) => {
            this.events=response.body.count;
            this.summaryy[3].value= this.events
          })
}
}
