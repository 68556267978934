import { map } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter,ViewChild,TemplateRef } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ToastrMessages } from '../../../helpers/toaster.service';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { element } from '@angular/core/src/render3';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ViewCell } from 'ng2-smart-table'


@Component({
  selector: 'ngx-sponsersview',
  templateUrl: './view.component.html',
  styles: [`
    nb-card {
      transform: translate3d(0, 0, 0);
    }
  `],
})
export class ViewComponent implements OnInit {

  baseUrl = environment.baseUrl;
  imagesUrl = environment.imagesUrl;
  allGroups:any = [];
  userGroups = [];
  dropdownList = [];
  selectedItems:any;
  dropdownSettings:IDropdownSettings = {};
  selectedUserId:any;
  passwordField:string="";
  selectedUserName="";
  companyName="";
  
  settings = {
    mode: 'external',
    actions: {
      columnTitle:"Action",
      position: 'right', // left|right
      add:false,
      edit:false
     },
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },

    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },

    custom : [{
      name : "groups",
      title : '<i class="nb-trash"></i>'
    }]
    ,   
    columns: {
      email:{
        title: 'Email',
        type: 'string'
      },
      name: {
        title: 'User Name',
        type: 'string',
      },
      status: {
        title: 'Status',
        type: 'custom',
        filter: false,
        renderComponent: ButtonViewComponent,
        onComponentInitFunction(instance) {
        instance.save.subscribe(row => {
        });
    }
      },

    //   changePassword: {
    //     title: 'Change password',
    //     type: 'custom',
    //     renderComponent: ButtonViewComponent,
    //     onComponentInitFunction(instance) {
    //     instance.save.subscribe(row => {
    //     });
    // }
    //   },
    //   company: {
    //     title: 'Company name',
    //     type: 'custom',
    //     renderComponent: ButtonViewComponent,
    //     onComponentInitFunction(instance) {
    //     instance.save.subscribe(row => {
    //     });
    // }
    //   },
       image: {
        title: 'Image',
        type: 'html',
        filter: false,
        valuePrepareFunction: (image: string) => `<img width="30px" src="${this.imagesUrl}${image}" alt=""/>`,
      }
     
    },


  };
  source: LocalDataSource = new LocalDataSource();
  @ViewChild("changePasswordModel") modalContent: TemplateRef<any>;
  
  @ViewChild("companyModel") companyModel: TemplateRef<any>;
  


  constructor( 
    private http: HttpClient,
    private router: Router,
    private toast: ToastrMessages,
    private datePipe:DatePipe,private _NgbModal: NgbModal) { 
     


    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }
  ngOnInit() {
    this.getAllUser();
    this.getAllGroups();
   
  }

  getAllUser(){
     
    this.http.get(this.baseUrl + 'getUsersList').subscribe(
      (response: any) => {


        response.body.map(item=> {
         item['changePassword']="2";
         item['company']="3";
        }) 
       this.source.load(response.body);
     
      },
      (error) => {
  });   

  }





  onDelete(event): void {
    if (confirm('Are you sure to delete this user?')) {
      this.http.delete(this.baseUrl + 'appusers/' + event.data.id)
        .subscribe(
          (response: any) => {
              this.http.get(this.baseUrl + 'getUsersList').subscribe(
              (newresponse: any) => {
                if (response.message == 'Deleted') {
                
                  this.toast.showToast(NbToastStatus.SUCCESS, 'App Users', response.body);
                }
                this.source.load(newresponse.body);
              },
              (error) => {
              });
        });
    }
  }
  onEdit(item,modelId){
       
       this.selectedUserId =item.data.id;
       this.selectedUserName = item.data.name;

       this.http.get(this.baseUrl + 'userGoupsList/'+item.data.id).subscribe(
        (userGroups: any) => {
        //  this.getAllGroups()
        this.allGroups.map(item=>{
          item.status=false;
        })
          console.log(userGroups);
         if(userGroups.body.length>0){
           userGroups.body.forEach(element => {
           let index = this.allGroups.findIndex(item=>item.id==element.groupId)
            if(index!=-1){
              this.allGroups[index].status=true;
             }else{
             // this.allGroups[index].status=false;
             }
          });

         }
          

        },
        (error) => {
       });
       
    // this.selectedItems=item.data.groups;
     this._NgbModal.open(modelId, {
      windowClass: 'modal-job-scrollable'
   });
   

  }


  getAllGroups(){
      this.http.get(this.baseUrl + 'categories').subscribe(
      (response: any) => {    
       
      response.body.map(item=> {
        item.id = item.id;
        item.name = item.name;
        item.image = item.image;

        item['status']=false;      

      }) 

      this.allGroups = response.body;

     // this.allGroups.sort()

      this.allGroups.sort((a, b) =>{
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
      });
     // console.log(this.getAllGroups);
       
      },
      (error) => {
      });
  }


  modifyGroupAccess(item,event){
 
    let status='0';
    if(event){
      status='2'
    }
    
   let data= {
     groupId:item.id,
     userId:this.selectedUserId,
     status:status
   }

   this.http.put(this.baseUrl + 'updateUserGroupAccess',data).subscribe(
    (response: any) => {
      console.log(response);
    },
    (error) => {
    });
  }

  getGroupStatus(){
    return true;
  }

 

  public activateAccount(renderValue){
   
     let status='0'
     if(renderValue.status==0){
      status = '1';
     }else if(renderValue.status==1){
      status = '0';
     }
    
   
              let data = {
                userId:renderValue.id,
                status : status
              } 

              this.http.post(this.baseUrl + 'updateUserStatus',data).subscribe(
                (response: any) => {

                  if (response.message == 'Updated') {
                          
                    this.toast.showToast(NbToastStatus.SUCCESS, 'Users', response.body);
                    this.getAllUser();
                  }
                  

                },
                (error) => {
                  this.toast.showToast(NbToastStatus.DANGER, 'Users', "Unable to upldate there is some error");

                });
           
  }


  changePassword(data){
    this.passwordField="";
    this.selectedUserId = data.id
    this._NgbModal.open(this.modalContent, {
      windowClass: 'modal-job-scrollable'
   });
  }


  companyModelOpen(data){
    console.log(data);
    this.companyName="";

    this.passwordField="";
    this.selectedUserId = data.id
    if(data.companyName!=null){
      this.companyName = data.companyName;
    }
    this._NgbModal.open(this.companyModel, {
      windowClass: 'modal-job-scrollable'
   });

  }

 updateCompanyData(){

  let data = {
    userId:this.selectedUserId,
    companyName : this.companyName
  } 

  this.http.post(this.baseUrl + 'addUpdateCompany',data).subscribe(
    (response: any) => {
      if (response.message == 'Updated') {
         this._NgbModal.dismissAll();
         this.toast.showToast(NbToastStatus.SUCCESS, 'Users', response.body);
         this.getAllUser();
       }
    },
    (error) => {
     // console.log(error);
     this._NgbModal.dismissAll();
      this.toast.showToast(NbToastStatus.DANGER, 'Users', error.error.message);
    });

  
 }





  updatePassword(){

    let data = {
      userId:this.selectedUserId ,
      password : this.passwordField
    } 

    this.http.post(this.baseUrl + 'userPasswordUpdateAdmin',data).subscribe(
      (response: any) => {
        if (response.message == 'Success') {
           this._NgbModal.dismissAll();
          this.toast.showToast(NbToastStatus.SUCCESS, 'Users', response.body);
         }
      },
      (error) => {
        console.log(error);
        this.toast.showToast(NbToastStatus.DANGER, 'Users', error.error.message);
      });
  }

}

@Component({
  selector: 'button-view',
  template: `
    <button class="btn btn-demo btn-success btn-sm" (click)="onClick()" *ngIf="renderValue=='0'">Activate</button>
    <button class="btn btn-demo btn-success btn-sm" (click)="onClick()" *ngIf="renderValue=='1'">Deactivate</button>
    <button  class="btn btn-demo btn-success btn-sm" (click)="triggerChangePassword()" *ngIf="renderValue=='2'">Change Password</button>
    <button  class="btn btn-demo btn-success btn-sm" (click)="openCompanyModel()" *ngIf="renderValue=='3'">Add/Edit</button>
    `,
})
export class ButtonViewComponent implements ViewCell, OnInit {
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  constructor(private vewcomp:ViewComponent){

  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();
      console.log(this.renderValue);
  }

  onClick() {
   this.vewcomp.activateAccount(this.rowData);
  }

  triggerChangePassword(){
    this.vewcomp.changePassword(this.rowData);

  }

  openCompanyModel(){

    this.vewcomp.companyModelOpen(this.rowData);

  }

}