import { Component, OnInit, Input, Output, EventEmitter,ViewChild,TemplateRef,ElementRef } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router,ActivatedRoute} from '@angular/router';
import { DatePipe } from '@angular/common';
import { ToastrMessages } from '../../../helpers/toaster.service';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';


@Component({
  selector: 'ngx-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {

  dataLoaded=false;
  baseUrl = environment.baseUrl;
  imagesUrl = environment.imagesUrl;
  groupUsers=[];
  settings = {
    mode: 'external',
    actions: {
      columnTitle:"Group users",
      position: 'right', // left|right
      add:false,
      delete:false,

    },
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="fa fa-eye"></i>',
      saveButtonContent: '<i class="fa fa-eye"></i>',
      cancelButtonContent: '<i class="fa fa-eye"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
   
      name: {
        title: 'Group Name',
        type: 'string',
      },
     
      image: {
        title: 'Group Image',
        type: 'html',
        filter: false,
        valuePrepareFunction: (image: string) => `<img width="30px" src="${this.imagesUrl}${image}" />`,
      },
     
    },
    
  };

  @ViewChild("groupsModel") elementView: ElementRef;

  source: LocalDataSource = new LocalDataSource();

  constructor(
    private http: HttpClient,
    private router: Router,
    private toast: ToastrMessages,
    private route: ActivatedRoute,
    private _NgbModal: NgbModal



  ) { }

  ngOnInit() {
  
  //  alert(this.route.snapshot.paramMap.get('id'));
    this.getAdminAllGroups()
  }


  getAdminAllGroups(){

    this.http.get(this.baseUrl + 'allAdminGroup/'+this.route.snapshot.paramMap.get('id')).subscribe(
      (response: any) => {
       // 

       response.body.map(item=> {
        item['name']= item.category.name;
        item['image']= item.category.image;
      }) 
      this.source.load(response.body);
     },
      (error) => {
      });
   
  }

  onEdit(event,id){
    this._NgbModal.open(this.elementView, {
      windowClass: 'modal-job-scrollable'
   });

  this.http.get(this.baseUrl + 'allGroupUsers/'+event.data.groupId).subscribe(
    (newresponse: any) => {
      this.dataLoaded=true;
      this.groupUsers = [];
    if(newresponse.body.length>0){
      this.groupUsers = newresponse.body;
      this.groupUsers.map(element=>{
        element['status']=false;
      })
    } 
    },
    (error) => {
      //this.dataLoaded=true;
    });

  }

  errorImage(data){
    return this.baseUrl+'/public/images/users/image-1596110430.jpg';
  }

  onDelete(data:any){

  }

}
