
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// export const environment = {
//   production: false,
//   baseUrl: 'http://localhost:3131/api/',
//   imagesUrl: 'http://localhost:3131/',
//   googleMapKey: '',
// };

export const environment = {
  production: false,
 baseUrl: 'https://hubsapponline.com/api/',
  //baseUrl: 'http://localhost:3131/api/',
 imagesUrl: 'https://hubsapponline.com/',
 //  imagesUrl: 'http://localhost:3131',
  googleMapKey: '',
};

//new aws
// export const environment = {
//   production: false,
//   baseUrl: 'https://webchat.hilitehealth.com:3232/api/',
//   imagesUrl: 'https://webchat.hilitehealth.com:3232/',
//   googleMapKey: '',
// };





