import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppUsersComponents} from './root.component'
import { ViewComponent } from './view/view.component';
import {AddComponent} from './add/add.component';
import {GroupsComponent} from './groups/groups.component'
import {GroupusersComponent}  from './groupusers/groupusers.component'


const routes: Routes = [{
  path: '',
  component: AppUsersComponents,
  children: [
    {
      path: 'add',
      component: AddComponent,
    },{
    path: 'view',
    component: ViewComponent,
  },
  {
    path: 'groups',
    component: GroupsComponent,
  },

  {
    path: 'groups/:id',
    component: GroupsComponent,
  },
  {
    path: 'groupusers:id',
    component: GroupusersComponent,
  },


],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AppUsersRoutingModule { }
