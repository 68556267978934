import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ToastrMessages } from '../../../helpers/toaster.service';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';

@Component({
  selector: 'ngx-edit',
  styleUrls: ['./edit.component.scss'],
  templateUrl: './edit.component.html',
})
export class EditComponent implements OnInit {
  baseUrl = environment.baseUrl;
  imagesUrl = environment.imagesUrl;
  brandStatus: string;
  spinner = false;
  dspinner: boolean = false;
  cros: boolean;
  uniemails: any;
  urlpattren: string | RegExp = /^@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w\-]+\.)+)([a-zA-Z]{1,3}))$/;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastrMessages,

  ) {
    // =================================Get & set the university detail=============================
    this.http.get(this.baseUrl + 'page/' + this.route.snapshot.paramMap.get('id')).subscribe(
      (response: any) => {
        this.f['description'].setValue(response.body.description);
        // this.f['image'].setValue(response.body.image);
        // this.uniemails = response.body.universityEmails;
        // this.addemails(this.uniemails);
        // this.brandStatus = response.body.status;
        // this.imgURL = this.imagesUrl + response.body.image;
      }, (error) => {
        this.toast.showToast(NbToastStatus.DANGER, 'Invalid Brand!', 'Please Check Page Id');
      },
    );
  }
  imagePath;
  fileData: File = null;
  brandForm: FormGroup;
  submitted = false;
  imgURL: any;
  message: string;
  @ViewChild('form') form;
  ngOnInit() {
    // initilising form 
    this.brandForm = this.formBuilder.group({
      description: ['', [Validators.required, Validators.minLength(2)]],
      // image: [this.fileData],
      // units: this.formBuilder.array([
      // ]),
    });
  }
  // to add dynamic feilds in form 
  // private addUnit() {
  //   this.cros = true;
  //   const control = <FormArray>this.brandForm.controls['units'];
  //   control.push(this.getUnit());
  // }
  // private getUnit() {
  //   return this.formBuilder.group({
  //     email: ['', [Validators.required,Validators.compose([
  //       Validators.pattern(this.urlpattren),
  //     ])]],
  //   });
  // }
  // setting previous emails into form 
  // addemails(emailz) {
  //   this.cros = true;
  //   emailz.forEach(emails => {

  //     const control = <FormArray>this.brandForm.controls['units'];
  //     control.push(
  //       this.formBuilder.group({
  //         email: [emails.email, [Validators.required,Validators.compose([
  //           Validators.pattern(this.urlpattren),
  //         ])]],
  //       })
  //     );
  //   });

  // }

  // preivie image ans create a image data 
  preview(files) {
    if (files.length === 0) {
      return;
    }
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }
    this.f['image'].setValue(files[0].name ? files[0].name : '');
    const reader = new FileReader();
    this.imagePath = files;
    this.fileData = <File>files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }

  // convenience getter for easy access to form fields
  get f() { return this.brandForm.controls; }
  ////// ========================== updating university  function =========================== //////
  updateBrand() {
    this.submitted = true;
    if (this.brandForm.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('id', this.route.snapshot.paramMap.get('id'));
    formData.append('description', this.f.description.value);
    // formData.append('email', JSON.stringify(this.f.units.value));
    // if (this.fileData) {
    //   formData.append('image', this.fileData);
    //   formData.append('imageChanged', 'yes');
    // } else {
    //   formData.append('imageChanged', 'no');

    // }
    console.log(formData);
    this.http.put(this.baseUrl + 'updatePage', formData).subscribe(
      (response: any) => {
        if (response.message == 'Exists' || response.message == 'Error') {
          this.toast.showToast(NbToastStatus.DANGER, 'Error', response.body);
        } else if (response.message === 'Not Modified') {
          this.toast.showToast(NbToastStatus.WARNING, 'Brand', response.body);
        } else if (response.message == 'Updated') {
          this.toast.showToast(NbToastStatus.SUCCESS, 'Brand', response.body);
        }
      },
      (error) => {
        this.spinner = false;
        this.toast.showToast(NbToastStatus.DANGER, 'Error', error.error.message);
      });
  }


// to remove email from multiple 
  // remove(i) {
  //   const control = <FormArray>this.brandForm.controls['units'];
  //   if (control.length > 1) {
  //     control.removeAt(i);
  //   }
  //   if (control.length == 1) {
  //     this.cros = false;
  //   }
  // }
  ////// ========================== update university  status ================================= //////

  updateStatus(status: string) {
    this.spinner = true;
    this.http.put(this.baseUrl + 'brandStatus', { id: this.route.snapshot.paramMap.get('id'), status: status })
      .subscribe(
        (response: any) => {
          this.spinner = false;
          this.toast.showToast(NbToastStatus.SUCCESS, response.message, response.body);
          if (status == '1') {
            this.brandStatus = '1';
          } else {
            this.brandStatus = '0';
          }
        }, (error) => {
          this.spinner = false;
          this.toast.showToast(NbToastStatus.DANGER, 'Error', 'Something Went Wrong.Please Try Again!');
        });
  }

  ////// ========================== Delete university status ================================= //////

  deleteBrand(id: number) {
    if (confirm('Are you sure to delete this Brand?')) {
      this.dspinner = true;
      this.http.delete(this.baseUrl + 'brand/' + this.route.snapshot.paramMap.get('id'))
        .subscribe(
          (response: any) => {
            this.router.navigate(['/pages/brands/view']);
          }, (error) => {
            this.dspinner = false;
            this.toast.showToast(NbToastStatus.DANGER, 'Error', 'Something Went Wrong.Please Try Again!');
          });
    }
  }
}
