import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild,TemplateRef } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NbDateService } from '@nebular/theme';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';
import { ToastrMessages } from '../../../helpers/toaster.service';
import { MENU_ITEMS } from '../../pages-menu';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { element } from '@angular/core/src/render3';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { runInThisContext } from 'vm';
@Component({
  selector: 'ngx-adsAdd',
  styleUrls: ['./add.component.scss'],
  templateUrl: './add.component.html',
})
export class AddComponent implements OnInit {


  @Output() positionChanged = new EventEmitter<Location>();
  @ViewChild('search')
  public search: ElementRef;
  time: any;
  tcode: string;
  menu: any;
  selected: any;
  validEmail: boolean;
  emailError:any;
  UNameError="";
  phoneError=""
  formError: string;
  result: string;
  wrongJson=true;
  uploadError="There is some column missing into the uploaded file or some wrong data - invalid email or password length less then 8 character or name & phone not present. Please check and upload again.";
  dropdownList = [];
  selectedItems:any;
  dropdownSettings:IDropdownSettings = {};
  jsonUsers:any=[]
  registrationStatus:any=[];


  constructor(
    private formBuilder: FormBuilder,
    private dateService: NbDateService<Date>,
    private http: HttpClient,
    private toast: ToastrMessages,
    private _NgbModal: NgbModal
    
  ) {
  }
  baseUrl = environment.baseUrl;

  spinner = false;
  imagePath;
  fileData: File = null;
  eventForm: FormGroup;
  submitted = false;
  imgURL: any;
  message: string;
  @ViewChild("failedUserRegistration") registratonFailed: TemplateRef<File>;
  @ViewChild("myInput") csvInput:ElementRef;


  @ViewChild('form') form;
  ngOnInit() {
    this.emailError='';
  var data=[];
  for(let i=0;i < MENU_ITEMS.length; i++){
    if(i==0){

    }
    else if(i==1){

    }
    else if(i==2){

    }
    else{
        data.push(MENU_ITEMS[i])
    }
  }
    
    this.menu=data;
    this.eventForm = this.formBuilder.group({
      uName: ['', [Validators.required, Validators.minLength(4)]],
      image: ['',[Validators.required]],
      pwd: ['', [Validators.required, Validators.minLength(8)]],
      phone: ['',[Validators.required, Validators.min(10000)]],
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    });
    this.imgURL = '';
    this.f['image'].setValue('');

    this.dropdownList = [
      ];
    this.selectedItems = [
     
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
   this.getAllGroups();

  }

  getAllGroups(){
    
    this.http.get(this.baseUrl + 'categories').subscribe(
      (response: any) => {
         this.getAllGroups = response.body;
         response.body.map(item=> {
          item.id = item.id;
          item.name = item.name;
        }) 

       this.dropdownList = response.body;
       
      },
      (error) => {
      });
  }

 
  phone(){

  }
  uName(){

  }
  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      this.toast.showToast(NbToastStatus.DANGER, 'Error', this.message);
      return;
    }

    this.f['image'].setValue(files[0].name ? files[0].name : '');
    const reader = new FileReader();
    this.imagePath = files;
    this.fileData = <File>files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }
  // convenience getter for easy access to form fields
  get f() { return this.eventForm.controls; }
  ////// ========================== super admin login function =========================== //////

  email(){

}

  onItemSelect(item){
    console.log(this.selectedItems);
  }

  onSelectAll(item){
    this.selectedItems = [];
      this.dropdownList.forEach(element => {
       this.selectedItems.push(element)
     });
     console.log(this.selectedItems);
     
  }
  onDeSelect(item){
    console.log(this.selectedItems);
   
  } 

  addEvent() {

    let groups:any=[];

    this.submitted = true;
   
    if (this.eventForm.invalid) {
    
      return;
    }

    if(this.selectedItems.length>0){
           this.selectedItems.forEach(element=>{
            groups.push(element.id)
       })
    }

    // if(this.message){
    //   this.toast.showToast(NbToastStatus.DANGER, 'Error', this.message);
    //   return;
    // }
      

    // --------------------------------------------------------------          
    this.spinner = true;
    this.formError=""
    const formData = new FormData();
    formData.append('name', this.f.uName.value);
    formData.append('password', this.f.pwd.value);
    formData.append('email', this.f.email.value);
    formData.append('image', this.fileData);
    formData.append('phone', this.f.phone.value);
    formData.append('authkey', "authkey");
    formData.append('p256dh', "authkey");
    formData.append('groups',groups);
    this.http.post(this.baseUrl + 'addappuser', formData).subscribe(
      (response: any) => {
        this.spinner = false;
        if (response.message === 'Exists' || response.message === 'Error') {
          this.toast.showToast(NbToastStatus.DANGER, 'Error', response.body);
        } else if (response.message === 'Added') {
          this.toast.showToast(NbToastStatus.SUCCESS, 'User', response.body);
          this.form.nativeElement.reset();
          this.selectedItems=[];
          this.submitted = false;
          this.fileData = null;
          this.imgURL = '';
          this.f['image'].setValue('');
         
        }
      },
      (error) => {
        this.spinner = false;
        this.toast.showToast(NbToastStatus.DANGER, 'Error', 'Something Went Wrong.Please Try Again!');
      });
  }


  uploadCsv(evt){
    let  ref = this;
    var file = evt.target.files[0];
    var reader = new FileReader();
    reader.readAsText(file);
     reader.onload = function(event:any) {
      var csvData = event.target.result;
      console.log(csvData);
      ref.jsonUsers =  ref.csvJSON(csvData)
     
      ref.wrongJson  =   ref.jsonUsers.every(item => item.hasOwnProperty("email")
      && item.hasOwnProperty("name")
      && item.hasOwnProperty("password") 
      
    
  );

 if(ref.wrongJson==false){
  ref.jsonUsers = [];
 }

 ref.jsonUsers.forEach(element => {

    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if(element.name=="" || !(reg.test(element.email)) || element.password.length<7 || element.phone==""){
            
      ref.jsonUsers = [];
      ref.wrongJson=false;
    } 
   
 });

    
  

    


      
  };
  reader.onerror = function() {
      alert('Unable to read ' + file.fileName);
  };
  
  }


  csvJSON(csv) {
    var lines=csv.split("\n");
    var result = [];
    var commaRegex = /,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/g
    var quotesRegex = /^"(.*)"$/g
    var headers = lines[0].split(commaRegex).map(h => h.replace(quotesRegex, "$1"));
    for(var i=1;i<lines.length;i++){
        var obj = {};
        var currentline=lines[i].split(commaRegex);
        for(var j=0;j<headers.length;j++){
            obj[headers[j]] = String(currentline[j]).replace(quotesRegex, "$1");
        }
        result.push(obj);
    }
    result.pop();
    return result;
  
  
  }


  async uploadJson(){
    console.log(this.jsonUsers)

    this.spinner=true;

    await Promise.all(this.jsonUsers.map(async item => {
          
     
      const  data = {
        name: item.name,
        password: item.password,
        email:item.email,
        company:item.company,
        authkey:"authkey",
        p256dh:"p256dh",
        phone:item.phone
      }
   
      

      this.http.post(this.baseUrl + 'addMultipleUsers', data).subscribe(
        (response: any) => {

          if(response.message=="false"){

            let obj = {
              email:item.email,
              status:"Email already registered"
            }

           this.registrationStatus.push(obj)

           

          }else{

            let obj = {
              email:item.email,
              status:"Registration successful"
            }
            this.registrationStatus.push(obj)

          }
        
          this.spinner = false;
          
        },
        (error) => {
          this.spinner = false;
          this.toast.showToast(NbToastStatus.DANGER, 'Error', 'Something Went Wrong.Please Try Again!');
        });
    }))

    this.spinner=false;

    this._NgbModal.open(this.registratonFailed, {
      windowClass: 'modal-job-scrollable'
   });
   
   
  }

  closeModel(){
    this._NgbModal.dismissAll();
    this.registrationStatus=[];
    this.jsonUsers=[];
   
    this.csvInput.nativeElement.value = "";
    
  }




}
