import { Component, OnInit, ViewChild, NgZone, ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ToastrMessages } from '../../../helpers/toaster.service';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';
import { Router } from '@angular/router';

import {} from 'googlemaps';
import { MapsAPILoader } from '@agm/core';
@Component({
  selector: 'ngx-add',
  styleUrls: ['./add.component.scss'],
  templateUrl: './add.component.html',
})
export class AddComponent implements OnInit {

  numbers:any

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private toast: ToastrMessages,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private router:Router
  ) {



  }
  baseUrl = environment.baseUrl;
  imagePath;
  fileData: File = null;
  categoryForm: FormGroup;
  submitted = false;
  imgURL: any;
  message: string;
  spinner = false;
  latitude: any;
  longitude: any;
  address: any;
  language: any;
  @ViewChild('form') form;
  @ViewChild('search', null)
  public searchElementRef: ElementRef;
  ngOnInit() {
    this.initAutocomplete();
     this.numbers = Array.from(Array(101).keys());
     this.numbers.splice(0,1);
     this.categoryForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4)]],
      address: ['', [Validators.required ]],
      language: ['', [Validators.required ]],
      image: [this.fileData, Validators.required]

  });
  }
  preview(files) {
    if (files.length === 0) {
      return;
    }
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      this.toast.showToast(NbToastStatus.DANGER, 'Error', this.message);
      return;
    }
    this.f['image'].setValue(files[0].name ? files[0].name : '');
    const reader = new FileReader();
    this.imagePath = files;
    this.fileData = <File>files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }

   // convenience getter for easy access to form fields
   get f() { return this.categoryForm.controls; }

   initAutocomplete() {
    console.log('initAutocomplete enters...');
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.address = place.formatted_address
          console.log(this.latitude, this.longitude)
          console.log("place...", place)

        });
      });
    });

  }

   ////// ========================== add Category function =========================== //////
     addCategory() {
      this.submitted = true;
      if (this.categoryForm.invalid) {
        return;
      }
      this.spinner = true;
      const formData = new FormData();
      formData.append('name', this.f.name.value);
      formData.append('address', this.address);
      formData.append('latitude', this.latitude);
      formData.append('language', this.f.language.value);
      formData.append('longitude', this.longitude);
      formData.append('image', this.fileData);
       
       this.http.post(this.baseUrl + 'category', formData).subscribe(
        (response: any) => {
          this.spinner = false;
          if (response.message === 'Exists' || response.message === 'Error') {
            this.toast.showToast(NbToastStatus.DANGER, 'Error', response.body);
           }  else if (response.message === 'Added') {
            this.toast.showToast(NbToastStatus.SUCCESS, 'Group', response.body);
            this.form.nativeElement.reset();
            this.imgURL = '';
            this.router.navigate(['pages/categories/view'])
// tslint:disable-next-line: forin
            for (const i in this.categoryForm.controls) {
              this.categoryForm.controls[i].setErrors(null);
            }
          }
      },
      (error) => {
        this.spinner = false;
        this.toast.showToast(NbToastStatus.DANGER, 'Error', 'Something Went Wrong.Please Try Again!');
      });
    }

}
