import { Component, OnDestroy } from '@angular/core';
import { TrafficList, TrafficListData } from '../../../@core/data/traffic-list';
import { TrafficBarData, TrafficBar } from '../../../@core/data/traffic-bar';
import { takeWhile } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'ngx-traffic-reveal-card',
  styleUrls: ['./traffic-reveal-card.component.scss'],
  templateUrl: './traffic-reveal-card.component.html',
})
export class TrafficRevealCardComponent implements OnDestroy {

  private alive = true;
  baseUrl = environment.baseUrl;
  trafficBarData: TrafficBar;
  trafficListData: TrafficList;

  revealed = false;
  period: string = 'week';
  source: any;
  userArray = [] as any;

  constructor(private trafficListService: TrafficListData,
    private trafficBarService: TrafficBarData, private http: HttpClient) {
    this.getTrafficFrontCardData(this.period);
    this.getTrafficBackCardData(this.period);
  }

  toggleView() {
    this.revealed = !this.revealed;
  }

  setPeriodAngGetData(value: string): void {
    this.period = value;

    this.getTrafficFrontCardData(value);
    this.getTrafficBackCardData(value);
  }

  getTrafficBackCardData(period: string) {
    this.trafficBarService.getTrafficBarData(period)
      .pipe(takeWhile(() => this.alive))
      .subscribe(trafficBarData => {
        this.trafficBarData = trafficBarData;
      });
  }

  getTrafficFrontCardData(period: string) {
    this.trafficListService.getTrafficListData(period)
      .pipe(takeWhile(() => this.alive))
      .subscribe(trafficListData => {
        this.trafficListData = trafficListData;
      });


    this.http.get(this.baseUrl + 'getUsersList').subscribe(
      (response: any) => {

        for (let i = 0; i < response.body.length; i++) {
          const x = {
            date: response.body[i].id,
            value: response.body[i].userName,
            email: response.body[i].appUser.email,
            dateCreated: response.body[i].appUser.dateCreated,
            delta: { up: '', value: response.body[i].appUser.email },
            comparison: { nextDate: response.body[i].appUser.dateCreated },

          };
          this.userArray.push(x); //push final array
        }

        if (this.userArray.length) {
          this.trafficListData = this.userArray;

        }
      },
      (error) => {
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }
}

// 1: {id: 3, userName: "harry",
// appUser: {email: "ASAs", dateCreated: "2019-04-12"}
// id: 1
// userName: "sam"
// __proto__: Object


// comparison: {prevDate: "Sun", prevValue: 75, nextDate: "Mon", nextValue: 59}
// date: "Mon"
// delta: {up: false, value: 16}
// value: 430
