import { map } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ToastrMessages } from '../../../helpers/toaster.service';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { element } from '@angular/core/src/render3';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ViewCell } from 'ng2-smart-table'


@Component({
  selector: 'ngx-sponsersview',
  templateUrl: './view.component.html',
  styles: [`
    nb-card {
      transform: translate3d(0, 0, 0);
    }
  `],
})
export class ViewComponent implements OnInit {

  baseUrl = environment.baseUrl;
  imagesUrl = environment.imagesUrl;
  allGroups: any = [];
  userGroups = [];
  dropdownList = [];
  selectedItems: any;
  dropdownSettings: IDropdownSettings = {};
  selectedUserId: any;
  passwordField: string = "";
  selectedUserName = "";
  companyName = "";

  settings = {
    mode: 'external',
    actions: {
      columnTitle: "Action",
      position: 'right', // left|right
      add: false,
      edit: false
    },
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },

    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },

    custom: [{
      name: "groups",
      title: '<i class="nb-trash"></i>'
    }]
    ,
    columns: {
      text: {
        title: 'Message',
        type: 'string'
      },
      image_url: {
        title: 'Image',
        type: 'html',
        filter: false,
        valuePrepareFunction: (image: string) => `<img width="30px" src="${this.imagesUrl}${image}" />`,
      },

    },


  };
  source: LocalDataSource = new LocalDataSource();



  constructor(
    private http: HttpClient,
    private router: Router,
    private toast: ToastrMessages,
    private datePipe: DatePipe, private _NgbModal: NgbModal) {



    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }
  ngOnInit() {
    this.getAllAnnouncemnets();

  }

  getAllAnnouncemnets() {

    this.http.get(this.baseUrl + 'getAllAnnouncements').subscribe(
      (response: any) => {
        for (let i = 0; i < response.body.length; i++) {
          const element = response.body[i];
          element.image_url = element.image_url ? element.image_url : element.video_thumb
        }
        this.source.load(response.body);

      },
      (error) => {
      });

  }





  onDelete(event): void {
    if (confirm('Are you sure to delete this announcement?')) {
      this.http.delete(this.baseUrl + 'deleteAnnouncement/' + event.data.id)
        .subscribe(
          (response: any) => {
            this.http.get(this.baseUrl + 'getAllAnnouncements').subscribe(
              (newresponse: any) => {
                if (response.message == 'Deleted') {

                  this.toast.showToast(NbToastStatus.SUCCESS, 'Announcement', response.body);
                }

                for (let i = 0; i < newresponse.body.length; i++) {
                  const element = newresponse.body[i];
                  element.image_url = element.image_url ? element.image_url : element.video_thumb
                }
                this.source.load(newresponse.body);
              },
              (error) => {
              });
          });
    }
  }



}
