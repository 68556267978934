import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NbDateService } from '@nebular/theme';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';
import { ToastrMessages } from '../../../helpers/toaster.service';
import { MENU_ITEMS } from '../../pages-menu';
import { LocalDataSource } from 'ng2-smart-table';
@Component({
  selector: 'ngx-adsAdd',
  styleUrls: ['./view.component.scss'],
  templateUrl: './view.component.html',
})
export class ViewComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private dateService: NbDateService<Date>,
    private http: HttpClient,
    private toast: ToastrMessages,
    
  ) {
  }

  settings = {
    mode: 'external',
    actions: {
      columnTitle:"Actions",
      add:false,
      position: 'right', // left|right
    },

    // add: {
    //   addButtonContent: '<i class="nb-plus"></i>',
    //   createButtonContent: '<i class="nb-checkmark"></i>',
    //   cancelButtonContent: '<i class="nb-close"></i>',
    // },

    edit: {
      editButtonContent: '<i class="ion-checkmark"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="ion-close"></i>',
      confirmDelete: true,
    },

   
    columns: {
      userName:{
        title: 'User',
        type: 'string'
      },
      groupName: {
        title: 'Group Name',
        type: 'string',
      },
      requestStatus:{
         title: 'Current Status',
         type: 'string',  
      }
     
    },
   

  };

  baseUrl = environment.baseUrl;
  spinner = false;
  imagePath;
  fileData: File = null;
  eventForm: FormGroup;
  submitted = false;
  imgURL: any;
  message: string;
  source: LocalDataSource = new LocalDataSource();

  ngOnInit() {
     this.getReqeustList();
  }

  getReqeustList(){
     this.http.get(this.baseUrl + 'getRequestList').subscribe(
      (response: any) => {
        response.body.map(item=>{
          item['userName'] = item['appUser.name'];
          item['groupName'] = item['category.name'];
          if(item.requestStatus=='2'){
            item.requestStatus="Enabled"
          }else{
            item.requestStatus="Requested or Disabled"
          }
          delete item['appUser.name'];
          delete item['category.name'];
          return item;

       })
       this.source.load(response.body.reverse());
      },
      (error) => {
    });     
  }



  onDeclined(item){
   //console.log(item.data);
   this.submitRequest(item.data.id, 0)
  }

  onAccept(item){
   // console.log(item.data);
    this.submitRequest(item.data.id, 2)

  }

  submitRequest(requestId, status){
    let obj = new Object();
    obj['requestId'] = requestId;
    obj['status'] = status;
    this.http.post(this.baseUrl + 'requestStatusUpdate', obj).subscribe(
      (response: any) => {
        this.toast.showToast(NbToastStatus.SUCCESS, 'Request', response.message);
        this.getReqeustList();
      },
      (error) => {
        this.toast.showToast(NbToastStatus.DANGER, 'Request',error);
    });     
  }
  
  onEdit(item){
    console.log(item);

  }


  

}
