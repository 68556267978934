import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ToastrMessages } from '../../../helpers/toaster.service';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { NbDateService } from '@nebular/theme';
import { Router } from '@angular/router';
import { MENU_ITEMS } from '../../pages-menu';

// import{SearchComponent} from '../../maps/search-map/search/search.component'
@Component({
  selector: 'ngx-sponsersedit',
  styleUrls: ['./edit.component.scss'],
  templateUrl: './edit.component.html',
})
export class EditComponent implements OnInit {
  baseUrl = environment.baseUrl;
  imagesUrl = environment.imagesUrl;
  spinner = false;
  selectcat:any;
  event: Location
  time: any;
  categoryStatus: string;
  validEmail: boolean;
  emailError: string;
  UNameError: string;
  formError: string;
  result: string;
  defineRole: any;
  newmenu= [];
  menu2= [];
  menui:any;
  menu1=MENU_ITEMS
  constructor(
    private dateService: NbDateService<Date>,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private toast: ToastrMessages,
    private router: Router,
  ) 
  {
    this.http.get(this.baseUrl + 'users/' + this.route.snapshot.paramMap.get('id')).subscribe(
      (response: any) => {
        this.f['uName'].setValue(response.body.userName);
        this.f['image'].setValue(response.body.image);
        this.f['email'].setValue(response.body.email);
        this.defineRole=response.body.roleId
        this.imgURL = this.imagesUrl + response.body.image;
        var array = this.defineRole.split(',');
      for(let i=0;i < array.length; i++){
        var x=array[i]-1;
        this.newmenu.push(MENU_ITEMS[x])
      }
  this.menu2 = this.newmenu;
  this.f['roles'].patchValue(this.menu2)

      }, (error) => {
        this.toast.showToast(NbToastStatus.DANGER, 'Invalid User!', 'Please Check User Id');
      },
    );
  }
  imagePath;
  fileData: File = null;
  eventForm: FormGroup;
  submitted = false;
  imgURL: any;
  menu: any;
  thumbURL: any;
  message: string;
  @ViewChild('form') form;
  ngOnInit() {
    var data=[];
    for(let i=0;i < MENU_ITEMS.length; i++){
      if(i==0){
  
      }
      else if(i==1){
  
      }
      else if(i==2){
  
      }
      else{
          data.push(MENU_ITEMS[i])
      }
    }
      
      this.menu=data;


    this.eventForm = this.formBuilder.group({
      uName: ['', [Validators.required, Validators.minLength(4)]],
      image: [this.fileData],
      pwd: [''],
      roles: ['',Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }
    this.f['image'].setValue(files[0].name ? files[0].name : '');
    const reader = new FileReader();
    this.imagePath = files;
    this.fileData = <File>files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }
  roles(){
    // console.log (this.f.roles.value)
    // this.selected=this.f.roles.value;
      }

  email(){
    if (this.f.email.invalid) {
    }
    else{
this.http.get(this.baseUrl + 'chaeckMail/'+ this.f.email.value).subscribe(
  (response: any) => {
    if(response.body=="Valid email"){
      this.validEmail=true;
      this.emailError='';
    }
    else{
      this.validEmail=false;
      this.emailError="err"
    }
  })
}
  }
  uName(){
    if (this.f.uName.invalid) {
     }
     else{
 this.http.get(this.baseUrl + 'chaeckUName/'+ this.f.uName.value).subscribe(
   (response: any) => {
    //  console.log(response)
     
     if(response.body=="Valid userName"){
       this.UNameError='';
     }
     else{
       this.UNameError="err"
     }
   })
 }
  }

  // convenience getter for easy access to form fields
  get f() { return this.eventForm.controls; }
  ////// ========================== super admin login function =========================== //////
updateEvent() {
    this.submitted = true;
    if(this.UNameError && this.emailError!=''){
      this.formError="Please input Valid data"
      return;
    }
    if (this.eventForm.invalid) {
      console.log("invalid")
      return;
    }

    // --------------------------------------------------------------          
    this.spinner = true;
    const formData = new FormData();
    formData.append('userName', this.f.uName.value);
   
    if(this.f.pwd.value !=''){
      formData.append('password', this.f.pwd.value);
    }
    for(let i=0;i<this.f.roles.value.length;i++){
      if(i>0){
        this.result += ','; 
        this.result += this.f.roles.value[i].index;
     }
     else if(i==0){
      this.result = this.f.roles.value[i].index;
     }
    }
    formData.append('roleId', this.result);
    formData.append('email', this.f.email.value);
    formData.append('image', this.fileData);
    formData.append('id', this.route.snapshot.paramMap.get('id'));
    if(this.fileData){
      formData.append('imagechnaged',"yes");
    }
    else{
      formData.append('imagechnaged',"no");
    }
    this.http.put(this.baseUrl + 'users', formData).subscribe(
      (response: any) => {
        this.spinner = false;
        if (response.message === 'Exists' || response.message === 'Error') {
          this.toast.showToast(NbToastStatus.DANGER, 'Error', response.body);
        } else if (response.message === 'Updated') {
          this.toast.showToast(NbToastStatus.SUCCESS, 'Users', response.body);
          for (const i in this.eventForm.controls) {
            this.eventForm.controls[i].setErrors(null);
          }
        }
      },
      (error) => {
        this.spinner = false;
        this.toast.showToast(NbToastStatus.DANGER, 'Error', 'Something Went Wrong.Please Try Again!');
      });
  }

  ////// ========================== update cateory status ================================= //////

  updateStatus(status: string) {
    this.spinner = true;
    this.http.put(this.baseUrl + 'userss', { id: this.route.snapshot.paramMap.get('id'), status: status })
      .subscribe(
        (response: any) => {
          this.spinner = false;
          this.toast.showToast(NbToastStatus.SUCCESS, response.message, response.body);
          if (status === 'Active') {
            this.categoryStatus = 'Active';
          } else {
            this.categoryStatus = 'Inactive';
          }
        }, (error) => {
          this.spinner = false;
          this.toast.showToast(NbToastStatus.DANGER, 'Error', 'Something Went Wrong.Please Try Again!');
        });
  }



  onDelete(): void {
    if (confirm('Are you sure to delete this User?')) {
      this.http.delete(this.baseUrl + 'users/' +this.route.snapshot.paramMap.get('id'))
        .subscribe(
          (response: any) => {
            this.router.navigate(['pages/users/view']);
         
              });
    }
  }
}
