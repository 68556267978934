import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ECommerceComponent } from './e-commerce/e-commerce.component';
import { NotFoundComponent } from './miscellaneous/not-found/not-found.component';
import { CategoriesModule } from './categories/categories.module';
import { AppUsersModule } from './appusers/appusers.module';
import { AnnouncementModule } from './announcements/announcement.module';
import { UsersModule } from './users/users.module';
import { RequestModule } from './requests/requests.module';
import { UserMsgModule } from './usermsg/usermsg.module';
import { RootModule } from './clients/root.module';
import { ProfilesModule } from './profiles/profiles.module';
import { CmsModule } from './cms/cms.module';

const routes: Routes = [{
  path: '',
  component: PagesComponent,
  children: [{
    path: 'dashboard',
    component: ECommerceComponent,
  },
  {
    path: 'users',
    loadChildren: () => UsersModule,
  },
  {
    path: 'appusers',
    loadChildren: () => AppUsersModule,
  },

  {
    path: 'categories',
    loadChildren: () => CategoriesModule,
  },

  {
    path: 'requests',
    loadChildren: () => RequestModule,
  },
  {
    path: 'usermsg',
    loadChildren: () => UserMsgModule,
  },

  {
    path: 'clients',
    loadChildren: () => RootModule,
  },
  {
    path: 'profiles',
    loadChildren: () => ProfilesModule,
  },

  {
    path: 'cms',
    loadChildren: () => CmsModule,
  },
  {
    path: 'announcement',
    loadChildren: () => AnnouncementModule,
  },
  {
    path: '**',
    component: NotFoundComponent,
  }],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
