import { Component, OnInit } from '@angular/core';
import { ProfitBarAnimationChartData } from '../../../../@core/data/profit-bar-animation-chart';
import { takeWhile } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { LiveUpdateChart, EarningData } from '../../../../@core/data/earning';
import { interval, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-stats-card-front',
  styleUrls: ['./stats-card-front.component.scss'],
  templateUrl: './stats-card-front.component.html',
})
export class StatsCardFrontComponent implements OnInit {
  baseUrl = environment.baseUrl;
  selectedCurrency: string = 'Tether';
  earningLiveUpdateCardData: LiveUpdateChart;
  private alive = true;
  intervalSubscription: Subscription;
  linesData: { firstLine: number[]; secondLine: number[] };
  liveUpdateChartData: { value: [string, number] }[];

  users: any;

  // tslint:disable-next-line: max-line-length
  constructor(
    private profitBarAnimationChartService: ProfitBarAnimationChartData, 
    private http: HttpClient, 
    private earningService: EarningData,
    private router: Router,
    ) {

    // this.profitBarAnimationChartService.getChartData()
    //   .pipe(takeWhile(() => this.alive))
    //   .subscribe((linesData) => {
    //     this.linesData = linesData;
    //     console.log(this.linesData)
    //   });
  }


  ngOnInit() {
    this.getEarningCardData(this.selectedCurrency);
    this.http.get(this.baseUrl + 'todayappusers').subscribe(
      (response: any) => {
        this.users = response.body.count;
      });
  }

  changeCurrency(currency) {
    if (this.selectedCurrency !== currency) {
      this.selectedCurrency = currency;

      this.getEarningCardData(this.selectedCurrency);
    }
  }

  private getEarningCardData(currency) {
    this.earningService.getEarningCardData(currency)
      .pipe(takeWhile(() => this.alive))
      .subscribe((earningLiveUpdateCardData: LiveUpdateChart) => {
        this.earningLiveUpdateCardData = earningLiveUpdateCardData;
        this.liveUpdateChartData = earningLiveUpdateCardData.liveChart;

        this.startReceivingLiveData(currency);
      });
  }

  startReceivingLiveData(currency) {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }

    this.intervalSubscription = interval(200)
      .pipe(
        takeWhile(() => this.alive),
        switchMap(() => this.earningService.getEarningLiveUpdateCardData(currency)),
      )
      .subscribe((liveUpdateChartData: any[]) => {
        this.liveUpdateChartData = [...liveUpdateChartData];
      });
  }

  onClickBox(): void{
    console.log("i am clicked")
    this.router.navigate(['/pages/appusers/view']);
  }



}
