import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NbDateService } from '@nebular/theme';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';
import { ToastrMessages } from '../../../helpers/toaster.service';
import { MENU_ITEMS } from '../../pages-menu';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { element } from '@angular/core/src/render3';
@Component({
  selector: 'ngx-adsAdd',
  styleUrls: ['./add.component.scss'],
  templateUrl: './add.component.html',
})
export class AddComponent implements OnInit {


  @Output() positionChanged = new EventEmitter<Location>();
  @ViewChild('search')
  public search: ElementRef;
  time: any;
  tcode: string;
  menu: any;
  selected: any;
  validEmail: boolean;
  emailError:any;
  UNameError="";
  phoneError=""
  formError: string;
  result: string;
  dropdownList = [];
  selectedItems:any;
  dropdownSettings:IDropdownSettings = {};


  constructor(
    private formBuilder: FormBuilder,
    private dateService: NbDateService<Date>,
    private http: HttpClient,
    private toast: ToastrMessages,
    
  ) {
  }
  baseUrl = environment.baseUrl;

  spinner = false;
  imagePath;
  fileData: File = null;
  eventForm: FormGroup;
  submitted = false;
  imgURL: any;
  message: string;

  @ViewChild('form') form;
  ngOnInit() {
    this.emailError='';
  var data=[];
  for(let i=0;i < MENU_ITEMS.length; i++){
    if(i==0){

    }
    else if(i==1){

    }
    else if(i==2){

    }
    else{
        data.push(MENU_ITEMS[i])
    }
  }
    
    this.menu=data;
    this.eventForm = this.formBuilder.group({
      uName: ['', [Validators.required, Validators.minLength(4)]],
      image: ['',[Validators.required]],
      pwd: ['', [Validators.required, Validators.minLength(8)]],
      phone: ['',Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
    this.imgURL = '';
    this.f['image'].setValue('');

    this.dropdownList = [
      ];
    this.selectedItems = [
     
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
   this.getAllGroups();

  }

  getAllGroups(){
    
    this.http.get(this.baseUrl + 'categories').subscribe(
      (response: any) => {
         this.getAllGroups = response.body;
         response.body.map(item=> {
          item.id = item.id;
          item.name = item.name;
        }) 

       this.dropdownList = response.body;
       
      },
      (error) => {
      });
  }

 
  phone(){

  }
  uName(){

  }
  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    this.f['image'].setValue(files[0].name ? files[0].name : '');
    const reader = new FileReader();
    this.imagePath = files;
    this.fileData = <File>files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }
  // convenience getter for easy access to form fields
  get f() { return this.eventForm.controls; }
  ////// ========================== super admin login function =========================== //////

  email(){

}

  onItemSelect(item){
    console.log(this.selectedItems);
  }

  onSelectAll(item){
    this.selectedItems = [];
      this.dropdownList.forEach(element => {
       this.selectedItems.push(element)
     });
     console.log(this.selectedItems);
     
  }
  onDeSelect(item){
    console.log(this.selectedItems);
   
  } 

  addEvent() {

    let groups:any=[];

    this.submitted = true;
   
    if (this.eventForm.invalid) {
    
      return;
    }

    if(this.selectedItems.length>0){
           this.selectedItems.forEach(element=>{
            groups.push(element.id)
       })
    }
      

    // --------------------------------------------------------------          
    this.spinner = true;
    this.formError=""
    const formData = new FormData();
    formData.append('name', this.f.uName.value);
    formData.append('password', this.f.pwd.value);
    formData.append('email', this.f.email.value);
    formData.append('image', this.fileData);
    formData.append('phone', this.f.phone.value);
    formData.append('authkey', "authkey");
    formData.append('p256dh', "authkey");
    formData.append('groups',groups);

    this.http.post(this.baseUrl + 'addappuser', formData).subscribe(
      (response: any) => {
        this.spinner = false;
        if (response.message === 'Exists' || response.message === 'Error') {
          this.toast.showToast(NbToastStatus.DANGER, 'Error', response.body);
        } else if (response.message === 'Added') {
          this.toast.showToast(NbToastStatus.SUCCESS, 'User', response.body);
          this.form.nativeElement.reset();
          this.selectedItems=[];
          this.submitted = false;
          this.fileData = null;
          this.imgURL = '';
          this.f['image'].setValue('');
         
        }
      },
      (error) => {
        this.spinner = false;
        this.toast.showToast(NbToastStatus.DANGER, 'Error', 'Something Went Wrong.Please Try Again!');
      });
  }
}
