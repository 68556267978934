
import { Component, OnInit, Input, Output, EventEmitter,ViewChild,TemplateRef } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ToastrMessages } from '../../../helpers/toaster.service';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';



@Component({
  selector: 'ngx-sponsersview',
  templateUrl: './view.component.html',
  styles: [`
    nb-card {
      transform: translate3d(0, 0, 0);
    }
  `],
})
export class ViewComponent implements OnInit {

  baseUrl = environment.baseUrl;
  imagesUrl = environment.imagesUrl;
  allGroups:any = [];
  userGroups = [];
  dropdownList = [];
  selectedItems:any;
  dropdownSettings:IDropdownSettings = {};
  selectedUserId:any;
  passwordField:string="";
  selectedUserName="";
  
  settings = {
    mode: 'external',
    actions: {
      columnTitle:"View Groups",
      position: 'right', // left|right
      add:false,
      delete:false,
     },
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },

    edit: {
      editButtonContent: '<i class="fa fa-eye"></i>',
      saveButtonContent: '<i class="fa fa-eye"></i>',
      cancelButtonContent: '<i class="fa fa-eye"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },

    custom : [{
      name : "View Groups",
      title : '<i class="nb-trash"></i>'
    }]
    ,   
    columns: {
      companyName:{
        title: 'Company Name',
        type: 'string'
      },
      name: {
        title: 'User Name',
        type: 'string',
      },
      image: {
        title: 'Image',
        type: 'html',
        filter: false,
        valuePrepareFunction: (image: string) => `<img width="30px" src="${this.imagesUrl}${image}" alt=""/>`,
      }
     
    },


  };
  source: LocalDataSource = new LocalDataSource();
  @ViewChild("changePasswordModel") modalContent: TemplateRef<any>;


  constructor( 
    private http: HttpClient,
    private router: Router,
    private toast: ToastrMessages,
    private datePipe:DatePipe,private _NgbModal: NgbModal) { 
     


    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }
  ngOnInit() {
    this.getAllUser();
   // this.getAllGroups();
   
  }

  getAllUser(){
     
    this.http.get(this.baseUrl + 'clientList').subscribe(
      (response: any) => {
        console.log(response.body);

        response.body.map(item=> {
          console.log("item", item);
          item['name']= item.appUser ? item.appUser.name : '';
          item['image']= item.appUser ? item.appUser.image : '';
          item['email']= item.appUser ? item.appUser.email : '';
          item['companyName']= item.appUser ? item.appUser.companyName : '';
          
        }) 
  
        response.body.sort((a, b) =>{
          if(a.name < b.name) { return -1; }
          if(a.name > b.name) { return 1; }
          return 0;
        });
      
 


        this.source.load(response.body);
     
      },
      (error) => {
  });   

  }

  onDelete(event:any): void {
   
  }

  onEdit(event:any):void{
    console.log(event.data.userId);
     this.router.navigate(['pages/clients/groups/' + event.data.userId]);
  }
  

}
