import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NbDateService } from '@nebular/theme';
import { HttpClient } from '@angular/common/http';
import { ToastrMessages } from '../../../helpers/toaster.service';
import { environment } from '../../../../environments/environment';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';

@Component({
  selector: 'ngx-announcementadd',
  styleUrls: ['./add.component.scss'],
  templateUrl: './add.component.html',
})
export class AddComponent implements OnDestroy {

  announcementForm: FormGroup;
  submitted = false;
  message: string;
  spinner: boolean;
  formError: string;
  fileData: File = null;
  videoFileData: File = null;
  videoThumbFileData: File = null;
  @ViewChild('form') form;

  constructor(
    private formBuilder: FormBuilder,
    private dateService: NbDateService<Date>,
    private http: HttpClient,
    private toast: ToastrMessages,

  ) {
    this.announcementForm = this.formBuilder.group({
      message: [''],
      image: [this.fileData],
      video: [this.videoFileData],
      video_thumb: [this.videoThumbFileData]
    });
  }

  ngOnint() {
    this.announcementForm = this.formBuilder.group({
      message: ['', []],
      image: [this.fileData],
      video: [this.videoFileData],
      video_thumb: [this.videoThumbFileData],
    });
  }

  setImage(files) {
    if (files.length === 0) {
      this.fileData = null
      return;
    }
    this.videoFileData = null;
    this.f['video'].setValue(null);
    this.f['image'].setValue(files[0].name ? files[0].name : '');
    this.fileData = <File>files[0];
  }

  setvideo(files) {
    if (files.length === 0) {
      this.videoFileData = null;
      return;
    }
    // this.f['image'].setValue(null);
    // this.fileData = null;
    this.f['video'].setValue(files[0].name ? files[0].name : '');
    this.videoFileData = <File>files[0];
  }

  setVideoThumb(files) {
    if (files.length === 0) {
      this.videoThumbFileData = null
      return;
    }

    this.f['video_thumb'].setValue(files[0].name ? files[0].name : '');
    this.videoThumbFileData = <File>files[0];
  }

  get f() { return this.announcementForm.controls; }

  baseUrl = environment.baseUrl;

  addAnnouncement() {
    this.submitted = true;
    this.spinner = true;
    this.formError = "";
    const formData = new FormData();
    formData.append('description', this.f.message.value);
    formData.append('image', this.fileData);
    formData.append('video', this.videoFileData);
    formData.append('video_thumb', this.videoThumbFileData);
    this.http.post(this.baseUrl + 'announcements', formData).subscribe(
      (response: any) => {
        this.spinner = false;
        this.toast.showToast(NbToastStatus.SUCCESS, 'Announcement', response.body);
        this.form.nativeElement.reset();
        this.submitted = false;
        this.fileData = null;
        this.videoFileData = null;
        this.videoThumbFileData = null;
        // tslint:disable-next-line: forin
        for (const i in this.announcementForm.controls) {
          this.announcementForm.controls[i].setErrors(null);
        }
      },
      (error) => {
        this.spinner = false;
        this.toast.showToast(NbToastStatus.DANGER, 'Error', 'Something Went Wrong.Please Try Again!');
      });
  }

  ngOnDestroy() {
  }

}
