import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CmsComponent } from './cms.component';
import { ViewComponent } from './view/view.component';
import { EditComponent } from './edit/edit.component';
import { CKEditorComponent } from './ckeditor/ckeditor.component';

const routes: Routes = [{
  path: '',
  component: CmsComponent,
  children: [ {
    path: 'view',
    component: ViewComponent,
  }, 
  // {
  //   path: 'edit/:id',
  //   component: EditComponent,
  // }, 
  {
    path: 'edit/:id',
    component: CKEditorComponent,
  }
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CmsRoutingModule { }
